import { formatDate, formatDescription } from "./BlogsUtils";

const BlogCard = ({ title, description, date, image, url }) => {
  return (
    <a
      href={`/blogs/${url}`}
      className="block bg-white shadow-md rounded-lg overflow-hidden mb-6 transform transition-transform duration-200 hover:scale-105 hover:shadow-xl cursor-pointer"
    >
      <div>
        <img src={image} alt="Blog" className="w-full h-48 object-cover" />
        <div className="p-4 flex flex-col justify-between">
          <h2 className="text-lg font-bold mb-2 line-clamp-2 ">{title}</h2>
          <p className="text-sm text-gray-600 mb-4">
            {formatDescription(description, 100)}
          </p>
          <div className="flex justify-between items-center">
            <span className="text-xs text-gray-500">{formatDate(date)}</span>
          </div>
        </div>
      </div>
    </a>
  );
};

export default BlogCard;
