/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import MbTitleBar from "../Common/MbTitleBar";
import { format } from "date-fns"; // date formatting
import Loading from "../Common/Loading";

const formatDate = (dateString) => format(new Date(dateString), "MMM dd yyyy");
const MbMyBooks = ({
  categorizedBooks,
  isLoading,
  handleReturnOrder,
  handleCancelOrder,
}) => {
  const tabs = [
    { id: 1, name: "In Process", books: categorizedBooks.in_process },
    { id: 2, name: "Books With Me", books: categorizedBooks.books_with_me },
    { id: 3, name: "Recent Order", books: categorizedBooks.recent_order },
  ];
  const status_colour = {
    "In Process": { bg: "yellow-100", text: "yellow-600" }, // Light yellow background, amber text
    "To Be Delivered": { bg: "blue-100", text: "blue-600" }, // Light blue background, dark blue text
    "To Be Picked Up": { bg: "orange-100", text: "orange-600" }, // Light orange background, amber text
    Cancelled: { bg: "red-100", text: "red-600" }, // Light red background, red text
    "Picked Up": { bg: "green-100", text: "green-600" }, // Light green background, emerald text
    Returned: { bg: "gray-200", text: "gray-700" }, // Light gray background, dark gray text
    Delivered: { bg: "green-100", text: "green-600" }, // Light green background, emerald text
  };

  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  useEffect(() => {
    if (categorizedBooks.in_process.length > 0) {
      setSelectedTab(tabs[0]);
    } else if (categorizedBooks.books_with_me.length > 0) {
      setSelectedTab(tabs[1]);
    } else if (categorizedBooks.recent_order.length > 0) {
      setSelectedTab(tabs[2]);
    } else {
      setSelectedTab(tabs[0]);
    }
  }, [categorizedBooks]);
  return (
    <>
      <MbTitleBar />
      {isLoading ? (
        <>
          <Loading />
        </>
      ) : (
        <>
          <div className="h-full w-full flex flex-col overflow-y-auto">
            <div className="w-full mt-6 px-[22px] grid grid-flow-col">
              {tabs.map((tab) => (
                <>
                  <div
                    className={`text-base font-semibold px-[12px] py-[10px] border-b-2 ${
                      selectedTab.id === tab.id ? "border-customBlue" : ""
                    }`}
                    onClick={() => setSelectedTab(tab)}
                  >
                    {" "}
                    {tab.name}
                  </div>
                </>
              ))}
            </div>

            <div className="w-full mt-6 flex flex-col  px-[22px] overflow-y-auto ">
              {selectedTab.books.map((book) => (
                <div className="bg-[#F7F9FB]  px-4  py-[16px] flex flex-col rounded-lg cursor-pointer justify-start mt-[18px]">
                  {selectedTab.name !== "In Process" && (
                    <div className="flex flex-row ">
                      {" "}
                      <div
                        className={` justify-center items-center flex px-2 py-1 bg-${
                          status_colour[book.order_status].bg
                        } border border-${
                          status_colour[book.order_status].text
                        }  text-${
                          status_colour[book.order_status].text
                        } text-xs font-semibold rounded-full
                   `}
                      >
                        {book.order_status}
                      </div>
                    </div>
                  )}

                  <div className="flex flex-row mt-2.5">
                    <div>
                      <img
                        src={book.image_url}
                        alt={book.title}
                        className="w-[43px] h-[66px] object-cover border"
                      />
                    </div>
                    <div className="flex flex-col ml-3">
                      <div className="text-lg font-semibold line-clamp-1 ">
                        {book.book_name}
                      </div>
                      <div className="text-xs text-gray-500 mt-0.5 line-clamp-1">
                        By {book.book_author}
                      </div>
                    </div>
                  </div>
                  <div className="text-xs text-gray-500 mt-2.5">
                    Order Id :{book.order_id}
                  </div>
                  <div className="text-xs text-gray-500 mt-2.5">
                    Order Date : {formatDate(book.order_created_at)}
                  </div>
                  {selectedTab.name === "In Process" &&
                    book.order_status === "To Be Delivered" && (
                      <>
                        <div className="w-full flex justify-center mt-3 bg-customBlue text-white px-5 rounded-lg ">
                          <button
                            className="h-11 "
                            onClick={() => handleCancelOrder(book)}
                          >
                            {" "}
                            Cancel Order
                          </button>
                        </div>
                      </>
                    )}
                  {selectedTab.name === "In Process" && (
                    <>
                      <div className="w-full flex justify-center items-center">
                        <div className="h-[1px] w-11/12 bg-gray-200 mt-3"></div>
                      </div>
                      <div className="w-full flex justify-start mt-3">
                        <OrderTracking book={book} />
                      </div>
                    </>
                  )}
                  {selectedTab.name === "Books With Me" &&
                    book.order_status === "Delivered" && (
                      <>
                        <div className="w-full flex justify-center mt-3 bg-customBlue text-white px-5 rounded-lg ">
                          <button
                            className="h-11 "
                            onClick={() => handleReturnOrder(book)}
                          >
                            {" "}
                            Mark For Pickup
                          </button>
                        </div>
                      </>
                    )}
                </div>
              ))}
            </div>
            <div className="h-[50px] mt-[50px]"></div>
          </div>
        </>
      )}
    </>
  );
};
export default MbMyBooks;

const OrderTracking = ({ book }) => {
  // Determine the progress based on `orderStatus`
  const isDispatched = book.order_status === "Dispatched";
  const isDelivered = false;

  return (
    <>
      <div className="w-full flex flex-col">
        <div className="text-base font-semibold mt-3">Order Tracking</div>
        <div className="w-full flex flex-row justify-start mt-10">
          {/* Tracking Steps */}
          <div className="px-2 flex flex-col items-center mt-1.5">
            {/* Rented Step */}
            <>
              <div className={`w-2.5 h-2.5 rounded-full bg-green-500`}></div>
              <div
                className={`h-[70px] w-0.5 ${
                  isDispatched ? "bg-green-500" : "bg-gray-300"
                }`}
              ></div>

              {/* Dispatched Step */}
              <div
                className={`w-2.5 h-2.5 rounded-full ${
                  isDispatched ? "bg-green-500" : "bg-gray-400"
                }`}
              ></div>
              <div
                className={`h-[70px] w-0.5 ${
                  isDelivered ? "bg-green-500" : "bg-gray-300"
                }`}
              ></div>

              {/* Delivered Step */}
              <div
                className={`w-2.5 h-2.5 rounded-full ${
                  isDelivered ? "bg-green-500" : "bg-gray-400"
                }`}
              ></div>
            </>
          </div>
          <>
            <div className="flex flex-col">
              <div className="">
                {" "}
                <div className="text-sm">Rented </div>
                <span className="text-gray-500 text-xs ">
                  {formatDate(book?.order_created_at)}
                </span>
              </div>
              <div className="text-sm mt-[36px]"> Dispatched </div>
              <span className="text-gray-500 text-xs ml-2">
                {isDispatched && formatDate(book?.order_updated_at)}
              </span>
              <div
                className={`text-sm ${
                  isDispatched ? "mt-[36px]" : "mt-[60px]"
                }`}
              >
                {" "}
                Delivered
              </div>
            </div>
          </>
        </div>
      </div>
    </>
  );
};
