import { useEffect, useState } from "react";
import { getSubscriptionDetails, getSubscriptions } from "./UserUtils";
import Loading from "../Common/Loading";

const SubscriptionComponent = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [subscriptionDetail, setSubscriptionDetail] = useState([]);

  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  useEffect(() => {
    getSubscriptionDetails({ setSubscriptionDetail, setIsLoading });
    getSubscriptions(setSubscriptionPlans);
  }, []);
  useEffect(() => {
    console.log(subscriptionDetail, subscriptionPlans);
  }, [subscriptionDetail, subscriptionPlans]);
  return (
    <>
      {isLoading ? (
        <>
          <Loading />
        </>
      ) : (
        <>
          <div className="flex flex-row mx-[35px] mt-[33px]  gap-6 overflow-auto ">
            {subscriptionPlans.map((plan) => (
              <>
                <div className=" min-w-[314px]  max-w-[314px] h-[554px] flex flex-col pt-[30px] px-[30px] bg-gray-600 text-white rounded-2xl">
                  <div className=" text-xl font-semibold">
                    {plan.subscription_type}
                  </div>
                  <div className="text-3xl mt-2 font-semibold">
                    {" "}
                    ₹{plan.subscription_price} /month
                  </div>
                  <div className="text-xs mt-4 line-clamp-3 h-[60px]">
                    {plan.subscription_description}
                  </div>
                  <div className="flex flex-col mt-[30px] gap-4 flex-grow">
                    <div className=" flex flex-row ">
                      <div className="w-[22px] h-[22px] bg-white flex items-center justify-center rounded-full">
                        <i className="fa fa-check text-customBlue"></i>
                      </div>
                      <div className="text-base font-semibold ms-3">
                        Validity {plan.subscription_validity} days
                      </div>
                    </div>
                    <div className="flex flex-row ">
                      <div className="w-[22px] h-[22px] bg-white flex items-center justify-center rounded-full">
                        <i className="fa fa-check text-customBlue"></i>
                      </div>
                      <div className="text-base font-semibold ms-3">
                        {plan.subscription_allowed_book_count} books allowed per
                        month
                      </div>
                    </div>
                    <div className="flex flex-row ">
                      <div className="w-[22px] h-[22px] bg-white flex items-center justify-center rounded-full">
                        <i className="fa fa-check text-customBlue"></i>
                      </div>
                      <div className="text-base font-semibold ms-3">
                        {plan.subscription_allowed_book_at_once} books allowed
                        at a time
                      </div>
                    </div>
                  </div>
                  <div className="flex mb-[34px] justify-center items-center">
                    <button className="w-[114px] h-11 flex items-center justify-center text-base bg-white font-semibold text-customBlue rounded-xl">
                      Subscribe
                    </button>

                  </div>
                </div>
              </>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default SubscriptionComponent;
