import { useEffect, useState } from "react";
import MbTitleBar from "../Common/MbTitleBar";
import { format } from "date-fns"; // date formatting
import Loading from "../Common/Loading";

const formatDate = (dateString) => format(new Date(dateString), "MMM dd yyyy");
const status_colour = {
  "In Process": { bg: "yellow-100", text: "yellow-600" }, // Light yellow background, amber text
  "To Be Delivered": { bg: "blue-100", text: "blue-600" }, // Light blue background, dark blue text
  "To Be Picked Up": { bg: "orange-100", text: "orange-600" }, // Light orange background, amber text
  Cancelled: { bg: "red-100", text: "red-600" }, // Light red background, red text
  "Picked Up": { bg: "green-100", text: "green-600" }, // Light green background, emerald text
  Returned: { bg: "gray-200", text: "gray-700" }, // Light gray background, dark gray text
  Delivered: { bg: "green-100", text: "green-600" }, // Light green background, emerald text
};
const DkMyBooks = ({
  categorizedBooks,
  isLoading,
  handleReturnOrder,
  handleCancelOrder,
}) => {
  const tabs = [
    { id: 1, name: "In Process", books: categorizedBooks.in_process },
    { id: 2, name: "Books With Me", books: categorizedBooks.books_with_me },
    { id: 3, name: "Recent Order", books: categorizedBooks.recent_order },
  ];

  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  useEffect(() => {
    if (categorizedBooks.in_process.length > 0) {
      setSelectedTab(tabs[0]);
    } else if (categorizedBooks.books_with_me.length > 0) {
      setSelectedTab(tabs[1]);
    } else if (categorizedBooks.recent_order.length > 0) {
      setSelectedTab(tabs[2]);
    } else {
      setSelectedTab(tabs[0]);
    }
  }, [categorizedBooks]);
  return (
    <>
      <div className="w-full  text-2xl font-semibold text-customBlue h-[84px] flex items-center ps-[35px] pt-[22px] pb-[33px] border-b">
        My Books
      </div>

      {isLoading ? (
        <>
          <Loading />
        </>
      ) : (
        <>
          <div className="h-full w-full flex flex-col overflow-y-auto">
            {tabs.map((tab) =>
              tab.name == "In Process" ? (
                <InProcessBooks
                  tab={tab}
                  handleCancelOrder={handleCancelOrder}
                />
              ) : (
                <>
                  {tab.books.length > 0 && (
                    <>
                      <>
                        <div className=" px-[34px] text-lg font-semibold mt-3">
                          {" "}
                          {tab.name}
                        </div>
                        <div className="w-full  flex flex-col  px-[34px] ">
                          {tab.books.map((book) => (
                            <div className="bg-[#F7F9FB]  border-[#CBDAEA] border   px-4  py-[16px] flex flex-row rounded-lg  justify-start mt-[18px]">
                              <div>
                                <img
                                  src={book.image_url}
                                  alt={book.title}
                                  className="w-[43px] h-[66px] object-cover border"
                                />
                              </div>

                              <div className="flex flex-col flex-grow ml-3">
                                <div className="text-lg font-semibold line-clamp-1 ">
                                  {book.book_name}
                                </div>
                                <div className="text-xs text-gray-500 mt-0.5 line-clamp-1">
                                  By {book.book_author}
                                </div>
                                <div className="text-xs text-gray-500 mt-2.5">
                                  Order Id :{book.order_id} | Order Date :{" "}
                                  {formatDate(book.order_created_at)}
                                </div>
                                <div className="text-xs text-gray-500 mt-2.5"></div>
                              </div>
                              {tab.name !== "In Process" && (
                                <div className="flex flex-col ">
                                  {" "}
                                  <div
                                    className={` justify-center items-center flex px-2 h-[32px] py-1 bg-${
                                      status_colour[book.order_status].bg
                                    } border border-${
                                      status_colour[book.order_status].text
                                    }  text-${
                                      status_colour[book.order_status].text
                                    } text-xs font-semibold rounded-full
                   `}
                                  >
                                    {book.order_status}
                                  </div>
                                  {tab.name == "Books With Me" &&
                                    book.order_status == "Delivered" && (
                                      <>
                                        <div className="w-full flex justify-center mt-3 bg-customBlue text-white px-5 rounded-lg ">
                                          <button
                                            className="h-11 "
                                            onClick={() =>
                                              handleReturnOrder(book)
                                            }
                                          >
                                            {" "}
                                            Mark For Pickup
                                          </button>
                                        </div>
                                      </>
                                    )}
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                        <div className=" w-full  flex justify-center items-center">
                          {" "}
                          <div className="h-[1px] w-11/12 bg-gray-200 mt-3"></div>
                        </div>
                      </>
                    </>
                  )}
                </>
              )
            )}

            <div className="h-[50px] mt-[50px]"></div>
          </div>
        </>
      )}
    </>
  );
};
export default DkMyBooks;

const InProcessBooks = ({ tab, handleCancelOrder }) => {
  return (
    <>
      {tab.books.length > 0 && (
        <>
          <>
            <div className=" px-[34px] text-lg font-semibold mt-3">
              {" "}
              {tab.name}
            </div>
            <div className="w-full  flex flex-col  px-[34px] ">
              {tab.books.map((book) => (
                <div className="bg-[#F7F9FB]  border-[#CBDAEA] border  px-4  py-[16px] flex flex-row rounded-lg  justify-start mt-[18px]">
                  <div>
                    <img
                      src={book.image_url}
                      alt={book.title}
                      className="w-[82px] h-[125px] object-cover border"
                    />
                  </div>
                  <div className="flex flex-col flex-grow">
                    {/* Title and order details */}
                    <div className="flex flex-row flex-grow ">
                      <>
                        <div className="flex flex-col flex-grow ml-3">
                          <div className="text-lg font-semibold line-clamp-1 ">
                            {book.book_name}
                          </div>
                          <div className="text-xs text-gray-500 mt-0.5 line-clamp-1">
                            By {book.book_author}
                          </div>

                          <div className="text-xs text-gray-500 mt-2.5"></div>
                        </div>
                      </>
                      <>
                        <div className="flex flex-col flex-grow items-end">
                          <div className="text-xs text-gray-500 mt-2.5 line-clamp-1 ">
                            Order Id :{book.order_id}
                          </div>
                          <div className="text-xs  text-gray-500 mt-0.5 line-clamp-1">
                            Order Date : {formatDate(book.order_created_at)}
                          </div>

                          <div className="text-xs text-gray-500 mt-2.5"></div>
                        </div>
                      </>
                    </div>{" "}
                    <div className=" flex flex-col">
                      <OrderTracking
                        book={book}
                        handleCancelOrder={handleCancelOrder}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className=" w-full  flex justify-center items-center">
              {" "}
              <div className="h-[1px] w-11/12 bg-gray-200 mt-3"></div>
            </div>
          </>
        </>
      )}
    </>
  );
};

const OrderTracking = ({ book, handleCancelOrder }) => {
  // Determine the progress based on `orderStatus`
  const isDispatched = book.order_status === "Dispatched";
  const isDelivered = false;

  return (
    <>
      <div className="w-full flex flex-row">
        {/* Order Tracking */}
        <div className=" flex flex-col ml-3 w">
          <div className="text-base font-semibold mt-3">Order Tracking</div>
          <div className="w-full flex flex-col justify-start mt-3">
            {/* Tracking Steps */}
            <div className="px-2 flex flex-row items-center mt-1.5">
              {/* Rented Step */}
              <>
                <div className={`w-2.5 h-2.5 rounded-full bg-green-500`}></div>
                <div
                  className={`w-[280px] h-0.5 ${
                    isDispatched ? "bg-green-500" : "bg-gray-300"
                  }`}
                ></div>

                {/* Dispatched Step */}
                <div
                  className={`w-2.5 h-2.5 rounded-full ${
                    isDispatched ? "bg-green-500" : "bg-gray-400"
                  }`}
                ></div>
                <div
                  className={`w-[280px] h-0.5 ${
                    isDelivered ? "bg-green-500" : "bg-gray-300"
                  }`}
                ></div>

                {/* Delivered Step */}
                <div
                  className={`w-2.5 h-2.5 rounded-full ${
                    isDelivered ? "bg-green-500" : "bg-gray-400"
                  }`}
                ></div>
              </>
            </div>
            <>
              <div className="flex flex-row">
                <div className="">
                  {" "}
                  <div className="text-sm">Rented </div>
                  <span className="text-gray-500 text-xs ">
                    {formatDate(book?.order_created_at)}
                  </span>
                </div>
                <div className="ml-[200px]">
                  <div className="text-sm "> Dispatched </div>
                  <span className="text-gray-500 text-xs ">
                    {isDispatched && formatDate(book?.order_updated_at)}
                  </span>
                </div>
                <div className={`text-sm  ml-[220px]`}> Delivered</div>
              </div>
            </>
          </div>
        </div>

        {/* Cancel Button */}
        <div className="flex flex-grow justify-end items-center">
          <button
            className="h-11  rounded-lg bg-customBlue px-2"
            onClick={() => handleCancelOrder(book)}
          >
            <span className="text-base font-semibold  text-white ">
              Cancel Order
            </span>
          </button>{" "}
        </div>
      </div>
    </>
  );
};
