import { useEffect, useState } from "react";
import {
  getChats,
  getMessages,
  handleDeleteChat,
  handleSendMessage,
} from "./ChatUtils";
import Loading from "../Common/Loading";
import MbChatComponent from "./MbChatComponent";
import DkChatComponent from "./DkChatComponent";

const ChatComponent = () => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [selectedShow, setSelectedShow] = useState("");
  const [selectedCharacter, setSelectedCharacter] = useState("");
  const [characterInput, setCharacterInput] = useState("");
  const [showInput, setShowInput] = useState("");
  const [isCharacterSelected, setIsCharacterSelected] = useState(false);
  const [chats, setChats] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isMessagesLoading, setIsMessagesLoading] = useState(false);

  const handleKeyPress = (e) => {
    if (e.key === "Enter")
      handleSendMessage(
        inputMessage,
        setInputMessage,
        setMessages,
        selectedShow,
        selectedCharacter,
        setIsMessagesLoading
      );
  };
  const handleSend = () => {
    handleSendMessage(
      inputMessage,
      setInputMessage,
      setMessages,
      selectedShow,
      selectedCharacter,
      setIsMessagesLoading
    );
  };

  useEffect(() => {
    getChats(setChats, setIsLoading);
  }, []);
  const handleCharacterSelect = (show, character) => {
    setSelectedShow(show.replace(/ /g, "-"));
    setSelectedCharacter(character.replace(/ /g, "-"));

    setIsCharacterSelected(true);
    getMessages(show + "_" + character, setMessages);
  };

  const handleNewChat = () => {
    if (characterInput === "" || showInput === "") return;
    setSelectedCharacter(characterInput.trim().replace(/ /g, "-"));
    setSelectedShow(showInput.trim().replace(/ /g, "-"));
    setIsCharacterSelected(true);
    setMessages([]);
  };
  return (
    <div className="flex flex-col h-full w-full  md:rounded-tl-2xl trabsition transition-all duration-300">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {/* Mobile Component */}
          <div className="h-full w-full md:hidden">
            <MbChatComponent
              chats={chats}
              characterInput={characterInput}
              showInput={showInput}
              setCharacterInput={setCharacterInput}
              setShowInput={setShowInput}
              handleNewChat={handleNewChat}
              handleCharacterSelect={handleCharacterSelect}
              setChats={setChats}
              setIsLoading={setIsLoading}
              isCharacterSelected={isCharacterSelected}
              inputMessage={inputMessage}
              setInputMessage={setInputMessage}
              handleSend={handleSend}
              handleKeyPress={handleKeyPress}
              messages={messages}
              isMessagesLoading={isMessagesLoading}
              setIsCharacterSelected={setIsCharacterSelected}
              handleDeleteChat={handleDeleteChat}
              selectedCharacter={selectedCharacter}
              selectedShow={selectedShow}
            />
          </div>
          <div className="hidden md:block h-full w-full">
            <DkChatComponent
              chats={chats}
              characterInput={characterInput}
              showInput={showInput}
              setCharacterInput={setCharacterInput}
              setShowInput={setShowInput}
              handleNewChat={handleNewChat}
              handleCharacterSelect={handleCharacterSelect}
              setChats={setChats}
              setIsLoading={setIsLoading}
              isCharacterSelected={isCharacterSelected}
              inputMessage={inputMessage}
              setInputMessage={setInputMessage}
              handleSend={handleSend}
              handleKeyPress={handleKeyPress}
              messages={messages}
              isMessagesLoading={isMessagesLoading}
              setIsCharacterSelected={setIsCharacterSelected}
              handleDeleteChat={handleDeleteChat}
              selectedCharacter={selectedCharacter}
              selectedShow={selectedShow}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ChatComponent;
