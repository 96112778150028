import { useState } from "react";
import CustomFloatingInput from "../Utilities/CustomFloatingInput";
import CustomFloatingSelect from "../Utilities/CustomFloatingSelect";
import { AddressRequest } from "../Serializers/SerializerModels";
import { saveAddress } from "./AddressUtils";
import Loading from "../Common/Loading";

const AddAddressComponent = ({ book, onClose, setAddress, address, toast }) => {
  const initialAddress = {
    name: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    pincode: "",
    state: "",
    country: "",
    phoneNumber: "",
  };

  const [editedAddress, setEditedAddress] = useState(initialAddress);
  const [isLoading, setIsLoading] = useState(false);

  const validateField = (name, value) => {
    switch (name) {
      case "name":
      case "addressLine1":
      case "city":
      case "state":
      case "country":
        if (!value.trim()) return false;
        break;
      case "pincode":
        if (!/^\d{5,6}$/.test(value)) return false;
        break;
      case "phoneNumber":
        if (!/^\d{10}$/.test(value)) return false;
        break;
      default:
        return true;
    }
    return true;
  };

  const handleInputChange = (e) => {
    setEditedAddress({ ...editedAddress, [e.target.id]: e.target.value });
  };
  const handleSelectChange = (e) => {
    setEditedAddress({ ...editedAddress, [e.target.id]: e.target.value });
  };
  const handleSave = () => {
    saveAddress(new AddressRequest(editedAddress), setIsLoading, toast);
    onClose();
  };
  const allValid = () => {
    let isValid = true;
    for (const [key, value] of Object.entries(editedAddress)) {
      if (!validateField(key, value)) {
        isValid = false;
        break;
      }
    }
    return isValid;
  };

  return (
    <div className=" w-full  h-full">
      {isLoading ? (
        <>
          <Loading />
        </>
      ) : (
        <>
          <div className="h-full flex flex-col w-full">
            {/* Header */}
            <div className="flex flex-row w-full h-84 px-[33px] items-center py-[27px]  shadow-md">
              <div className="text-2xl font-semibold text-customBlue line-clamp-1 w-11/12  flex justify-start ">
                {book?.title}
              </div>
              <div className="w-1/12 flex justify-end"></div>
              <div className="w-1/12 flex justify-end">
                <button
                  className="text-xl sm:text-2xl font-bold text-gray-600 hover:text-gray-800"
                  onClick={onClose}
                >
                  &times;
                </button>
              </div>
            </div>

            {/* Header 1 */}
            <div className="flex flex-row h-[56px] border-b  mx-[33px] mt-6  items-center ">
              <div className="text-xl font-semibold  text-customBlue line-clamp-1 w-11/12  flex justify-start mb-2">
                Enter Address
              </div>
            </div>

            <div className=" w-full flex-grow  flex flex-col  gap-4 px-[33px] mt-6 ">
              <div className="w-full ">
                <CustomFloatingInput
                  value={editedAddress.name}
                  type={"text"}
                  text={"Name"}
                  id={"name"}
                  onChange={(e) => handleInputChange(e)}
                  required={true}
                  isValid={validateField("name", editedAddress.name)}
                />
              </div>
              <div className="w-full ">
                <CustomFloatingInput
                  value={editedAddress.addressLine1}
                  type={"text"}
                  text={"Address Line 1"}
                  id={"addressLine1"}
                  onChange={(e) => handleInputChange(e)}
                  required={true}
                  isValid={validateField(
                    "addressLine1",
                    editedAddress.addressLine1
                  )}
                />
              </div>
              <div className="w-full ">
                <CustomFloatingInput
                  value={editedAddress.addressLine2}
                  type={"text"}
                  text={"Address Line 2"}
                  id={"addressLine2"}
                  isValid={validateField(
                    "addressLine2",
                    editedAddress.addressLine2
                  )}
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
              <div className="w-full flex flex-row gap-4">
                <div className="w-1/2">
                  <CustomFloatingSelect
                    values={[
                      { label: "", value: "" },
                      { label: "Pune", value: "Pune" },
                    ]}
                    label={"City"}
                    id={"city"}
                    onChange={(e) => handleSelectChange(e)}
                    isValid={validateField("city", editedAddress.city)}
                  />
                </div>
                <div className="w-1/2">
                  <CustomFloatingSelect
                    values={[
                      { label: "", value: "" },
                      { label: "Maharashtra", value: "Maharashtra" },
                    ]}
                    label={"State"}
                    id={"state"}
                    onChange={(e) => handleSelectChange(e)}
                    isValid={validateField("state", editedAddress.state)}
                  />
                </div>
              </div>
              <div className="w-full flex flex-row gap-4">
                <div className="w-1/2">
                  <CustomFloatingInput
                    value={editedAddress.pincode}
                    type={"text"}
                    text={"Pincode"}
                    id={"pincode"}
                    onChange={(e) => handleInputChange(e)}
                    isValid={validateField("pincode", editedAddress.pincode)}
                  />
                </div>
                <div className="w-1/2">
                  <CustomFloatingSelect
                    values={[
                      { label: "", value: "" },
                      { label: "India", value: "India" },
                    ]}
                    label={"Country"}
                    id={"country"}
                    onChange={(e) => handleSelectChange(e)}
                    isValid={validateField("country", editedAddress.country)}
                  />
                </div>
              </div>
              <div className="w-full ">
                <CustomFloatingInput
                  type={"number"}
                  text={"Phone Number"}
                  id={"phoneNumber"}
                  onChange={(e) => handleInputChange(e)}
                  isValid={validateField(
                    "phoneNumber",
                    editedAddress.phoneNumber
                  )}
                />
              </div>
              {/* Divider */}
              <div className="w-full h-[1px] bg-gray-200 shadow"></div>

              {/* Footer */}
            </div>
            <div className="flex items-end justify-end w-full h-[44px] my-[33px] px-[33px]  ">
              <button
                disabled={!allValid()}
                onClick={handleSave}
                className={`  flex flex-row items-center justify-center  w-[114px] ${
                  !allValid()
                    ? "bg-customSkyBlue text-customBlue"
                    : " bg-customBlue   text-white"
                } h-11 rounded-lg`}
              >
                <span className="ml-2">Save</span>
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AddAddressComponent;
