import React, { useState } from "react";
import { confirmOrder } from "./AddressUtils";
import { toast, ToastContainer } from "react-toastify";

const ConfirmationPage = ({ setStep, book, address, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleRent = () => {
    confirmOrder(book, address, toast, setIsLoading);
    setTimeout(() => {
      onClose();
    }, 3000);
  };
  return (
    <>
      <div className="flex flex-col w-full h-full">
        <ToastContainer />
        {/* Header */}
        <div className="flex flex-row w-full h-84 px-[33px] items-center py-[27px]  shadow-md">
          <div className="text-2xl font-semibold text-customBlue line-clamp-1 w-11/12  flex justify-start ">
            {book?.title}
          </div>
          <div className="w-1/12 flex justify-end"></div>
          <div className="w-1/12 flex justify-end">
            <button
              className="text-xl sm:text-2xl font-bold text-gray-600 hover:text-gray-800"
              onClick={onClose}
            >
              &times;
            </button>
          </div>
        </div>

        {/* Header 1 */}
        <div className="flex flex-row h-[56px] border-b  mx-[33px] mt-6  items-center ">
          <div className="text-xl font-semibold  text-customBlue line-clamp-1 w-11/12  flex justify-start mb-2">
            Order Confirmation
          </div>
        </div>

        {/* Book Details */}
        <div className=" w-full px-[33px]  flex flex-row mt-6 ">
          <div className=" mb-2">
            {" "}
            <img src={book?.image_url} alt={book?.title} />
          </div>
          <div className="flex flex-col ms-3">
            <div className="text-lg font-semibold text-customBlue line-clamp-1">
              {book?.title}
            </div>
            <div className="text-sm  text-gray-600 line-clamp-1">
              By {book?.author}
            </div>
            <div className="text-lg  mt-[16px] text-gray-600 line-clamp-1">
              {" "}
              Shipping Address:
            </div>
            <div className="text-md   text-gray-600 line-clamp-1 ">
              {address?.name}
            </div>
            <div className="text-sm   text-gray-600 line-clamp-1 ">
              {address?.phoneNumber}
            </div>
            <div className="text-sm   text-gray-600 line-clamp-1 ">
              {address?.addressLine1} {address?.addressLine2}, {address?.city},
            </div>
            <div className="text-sm   text-gray-600 line-clamp-1 ">
              {address?.state}, {address?.country}, {address?.pincode}
            </div>
          </div>
        </div>
        <div className="flex items-end justify-end w-full h-[44px] my-[33px] px-[33px]  ">
          <button
            disabled={isLoading}
            onClick={handleRent}
            className={`  flex flex-row items-center justify-center  w-[114px] ${
              isLoading
                ? "bg-customSkyBlue text-customBlue"
                : " bg-customBlue   text-white"
            } h-11 rounded-lg`}
          >
            <span className="ml-2">Continue</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default ConfirmationPage;
