import React, { useEffect, useState } from "react";
import { getBookByGenre } from "./HomeUtils";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "../Common/Loading";
import BookDetailComponent from "../Books/BookDetailComponent";
import HomeDk from "./HomeDk";
import HomeMb from "./HomeMb";
import MbBookDetailComponent from "../Books/mbBookDetailComponent";
import queryString from "query-string";
import SearchComponent from "../Search/SearchComponent";
import OrderComponent from "../Order/OrderComponent";

const HomeComponent = ({ setSelectedTab }) => {
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const [searchTerm, setSearchTerm] = useState(queryParams.query || "");
  const [isSearchTrue, setIsSearchTrue] = useState(
    queryParams.query ? true : false
  );
  const [genre1Books, setGenre1Books] = useState([]);
  const [genre2Books, setGenre2Books] = useState([]);
  const [trendingBooks, setTrendingBooks] = useState([]);
  const [scienceBooks, setScienceBooks] = useState([]);
  const [romanceBooks, setRomanceBooks] = useState([]);
  const [selfHelpBooks, setSelfHelpBooks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openBook, setOpenBook] = useState(null);
  const [isBookOrder, setIsBookOrder] = useState(null);

  const navigate = useNavigate();
  const orderBook = ({ book,onClose }) => {
    setIsBookOrder(book);
    
    if(onClose){
      onClose();
    }
  };

  const handleSearchRedirect = (searchTerm) => {
    if (searchTerm) navigate(`/search?query=${encodeURIComponent(searchTerm)}`);
    setIsSearchTrue(true);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearchRedirect(searchTerm);
    }
  };

  useEffect(() => {
    console.log(isBookOrder);
  }, [isBookOrder]);

  useEffect(() => {
    setSelectedTab("home");
    if (!trendingBooks.length)
      getBookByGenre("Trending", setTrendingBooks, setIsLoading);
    if (!genre1Books.length)
      getBookByGenre("Fiction", setGenre1Books, setIsLoading);
    if (!genre2Books.length)
      getBookByGenre("Fantasy", setGenre2Books, setIsLoading);
    if (!scienceBooks.length)
      getBookByGenre("Science", setScienceBooks, setIsLoading);
    if (!romanceBooks.length)
      getBookByGenre("Romance", setRomanceBooks, setIsLoading);
    if (!selfHelpBooks.length)
      getBookByGenre("Self-Help", setSelfHelpBooks, setIsLoading);
  }, []);

  return (
    <div className="flex flex-col h-full bg-white overflow-hidden  w-full ">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {isSearchTrue ? (
            <>
              <SearchComponent
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                handleKeyPress={handleKeyPress}
                handleSearchRedirect={handleSearchRedirect}
                setOpenBook={setOpenBook}
                setIsSearchTrue={setIsSearchTrue}
              />
            </>
          ) : (
            <>
              <>
                <div className="hidden md:flex flex-col h-full overflow-y-auto overflow-x-clip ">
                  {/* Title */}
                  <HomeDk
                    trendingBooks={trendingBooks}
                    genre1Books={genre1Books}
                    genre2Books={genre2Books}
                    scienceBooks={scienceBooks}
                    romanceBooks={romanceBooks}
                    selfHelpBooks={selfHelpBooks}
                    setOpenBook={setOpenBook}
                    handleSearchRedirect={handleSearchRedirect}
                    handleKeyPress={handleKeyPress}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    orderBook={orderBook}
                    onClose={() => setOpenBook(null)}
                  />
                </div>
                <div className="md:hidden flex flex-col h-full overflow-y-auto overflow-x-clip">
                  {/* Title */}
                  <HomeMb
                    trendingBooks={trendingBooks}
                    genre1Books={genre1Books}
                    genre2Books={genre2Books}
                    scienceBooks={scienceBooks}
                    romanceBooks={romanceBooks}
                    selfHelpBooks={selfHelpBooks}
                    setOpenBook={setOpenBook}
                    handleSearchRedirect={handleSearchRedirect}
                    handleKeyPress={handleKeyPress}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    orderBook={orderBook}
                    onClose={() => setOpenBook(null)}
                  />
                </div>
              </>
            </>
          )}
          {/* Book Detail Modal */}
          <>
            {openBook && (
              <>
                <div className="hidden md:flex">
                  {" "}
                  <BookDetailComponent
                    book={openBook}
                    setOpenBook={setOpenBook}
                    onClose={() => setOpenBook(null)}
                    orderBook={orderBook}
                  />
                </div>
                <div className="md:hidden">
                  <MbBookDetailComponent
                    setOpenBook={setOpenBook}
                    book={openBook}
                    onClose={() => setOpenBook(null)}
                    orderBook={orderBook}
                  />
                </div>
              </>
            )}
          </>
          {/* Book Order Modal */}
          <>
            {isBookOrder && (
              <>
                <OrderComponent
                  isBookOrder={isBookOrder}
                  setIsBookOrder={setIsBookOrder}
                />
              </>
            )}
          </>
        </>
      )}
    </div>
  );
};

export default HomeComponent;
