/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import AddressSelection from "./AddressComponent";

import { getBook } from "../Books/BooksUtil";
import ConfirmationPage from "./ConfirimationPage";
import { getSubscriptionDetails } from "../User/UserUtils";
import Loading from "../Common/Loading";
import { getAddress } from "./AddressUtils";

const OrderComponent = ({ isBookOrder, setIsBookOrder }) => {
  console.log("isBook being passed",isBookOrder);
  const book_id = isBookOrder.id;
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [booksDetails, setBooksDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [subscriptionDetail, setSubscriptionDetail] = useState([]);
  const [address, setAddress] = useState([]);

  const handleAddressChange = (address) => {
    setSelectedAddress(address);
  };

  const getBookFromId = async () => {
    await getBook({ book_id, setBooksDetails });
  };

  const getSubscriptionDetail = async () => {
    await getSubscriptionDetails({
      setSubscriptionDetail,
      setIsLoading,
    });
  };

  useEffect(() => {
    getSubscriptionDetail();
    if (book_id !== "") {
      getBookFromId();
    }
    getAddress({ setAddress, setIsLoading });
  }, []);

  const Content = () => {
    return (
      <div className="h-full  overflow-y-auto w-full ">
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {subscriptionDetail === null || subscriptionDetail.length === 0 ? (
              <ErrorNoValidSubscriptionFound />
            ) : (
              <>
                {book_id === "" ? (
                  <ErrorBookNotFound />
                ) : (
                  <div className="flex h-full w-full justify-center">
                    {selectedAddress === null ? (
                      <AddressSelection
                        handleAddressChange={handleAddressChange}
                        book={booksDetails}
                        onClose={() => setIsBookOrder(null)}
                        setAddress={setAddress}
                        address={address}
                      />
                    ) : (
                      <ConfirmationPage
                        book={booksDetails}
                        address={selectedAddress}
                        onClose={() => setIsBookOrder(null)}
                      />
                    )}
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="hidden md:flex">
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="fixed  inset-y-0 right-0 bg-white shadow-xl w-[568px] h-full rounded-l-lg ">
            <Content />
          </div>
        </div>
      </div>
      <div className="md:hidden flex">
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="fixed bottom-0  flex-col bg-white   z-50 w-full   h-10/12 min-h-[10/12] max-h-[11/12]   rounded-t-2xl ">
            <Content />
          </div>
        </div>
      </div>
    </>
  );
};

const ErrorBookNotFound = ({ navigate }) => {
  return (
    <>
      <div className="flex justify-center items-center h-1/3">
        <div className="shadow-xl p-4   bg-slate-300 rounded-md justify-center items-center">
          <div>Please Select the book to continue.</div>
          <div className="flex justify-center">
            <div
              className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded "
              onClick={() => navigate(-1)}
            >
              Go Back
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderComponent;

const ErrorNoValidSubscriptionFound = ({ navigate }) => {
  return (
    <div className="flex justify-center items-center h-full">
      <div className="flex justify-center items-center h-full">
        <div className="max-w-lg w-full bg-white rounded-xl shadow-lg p-6 transition duration-300 ease-in-out hover:shadow-xl">
          <div className="flex flex-col items-center">
            <h1 className="text-start mb-4 font-semibold text-2xl text-blue-800">
              Oops!! No Active Subscription
            </h1>
            <div className="text-center mb-4">
              You don't have any active subscription.
            </div>
            <div className="text-center mb-4">
              Subscribe to a plan now to get started:
            </div>
            <div className="flex justify-between w-full">
              <button
                className="mt-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                onClick={() => navigate(-1)}
              >
                Go Back
              </button>
              <a
                href="/subscription"
                className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Browse Plans
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
