import { useEffect, useState } from "react";
import {
  getSubscriptionDetails,
  getSubscriptions,
  userSubscription,
} from "./UserUtils";

const SubscriptionPlans = () => {
  const [subscriptionDetail, setSubscriptionDetail] = useState([]);

  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    getSubscriptionDetails({ setSubscriptionDetail, setIsLoading });
    getSubscriptions(setSubscriptionPlans);
  }, []);

  return (
    // <div className="bg-gray-100  py-10 px-5 h-full overflow-auto">
    //   <div className="container mx-auto h-full">
    //     <h2 className=" text-2xl font-bold mb-6 text-gary-800">
    //       Subscription Plans
    //     </h2>
    //     <div className=" grid grid-cols-1 md:grid-cols-3 justify-center p-4 gap-6 ">
    //       {subscriptionPlans.map((plan) => (
    //         <div
    //           key={plan.id}
    //           className="bg-white p-10 shadow-md hover:shadow-xl rounded-lg    "
    //         >
    //           <h3 className="text-xl font-semibold mb-4">
    //             {plan.subscription_name}
    //           </h3>
    //           <div className="flex items-center mb-4">
    //             <span className="text-2xl font-bold">
    //               ₹{plan.subscription_price}
    //             </span>
    //             <span className="text-gray-500 text-2xl font-bold">/month</span>
    //           </div>
    //           <div>
    //             <p className="text-gray-500 text-lg">
    //               {plan.subscription_description}
    //             </p>
    //           </div>
    //           <ul className="my-4 p-1 font-semibold flex flex-col gap-2  ">
    //             <li className="mb-2">
    //               <i className="fas fa-check text-green-500 mr-2" />
    //               <>{plan.subscription_allowed_book_count} Books per Month.</>
    //             </li>
    //             <li className="mb-2">
    //               {" "}
    //               <i className="fas fa-check text-green-500 mr-2" />
    //               {plan.subscription_allowed_book_at_once} Books at once.
    //             </li>
    //             <li
    //               className={`mb-2 ${
    //                 plan.subscription_free_delivery ? " " : "text-gray-500"
    //               } `}
    //             >
    //               <i
    //                 className={`fas fa-check ${
    //                   plan.subscription_free_delivery
    //                     ? "text-green-500"
    //                     : "text-gray-500"
    //                 } mr-2`}
    //               />
    //               Free delivery/pickup.
    //             </li>
    //             <li
    //               className={`mb-2 ${
    //                 plan.chatWithCharacters ? " " : "text-gray-500"
    //               } `}
    //             >
    //               <i
    //                 className={`fas fa-check ${
    //                   plan.chatWithCharacters
    //                     ? "text-green-500"
    //                     : "text-gray-500"
    //                 } mr-2`}
    //               />
    //               Chat with characters.
    //             </li>
    //           </ul>
    //           <button className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded w-full selection:mt-4"
    //             onClick={() => {
    //               userSubscription(plan);
    //             }}>
    //             {subscriptionDetail !==null && subscriptionDetail.subscription_id === plan.subscription_id
    //               ? "Start Now"
    //               : "Subscribe Now"}
    //           </button>
    //         </div>
    //       ))}
    //     </div>
    //   </div>
    // </div>
    <div class="h-screen w-full flex flex-col items-center justify-center text-center p-4 bg-gray-100">
      <p class="text-lg md:text-xl font-medium text-gray-800 mb-4">
        We do not offer subscription plans directly at this time. For assistance
        with subscribing, please feel free to contact us.
      </p>
      <p class="text-lg font-semibold text-gray-600">Thank you!</p>
      <a href="https://readkar.com">
        <button
          onclick="https://readkar.com"
          class="mt-6 px-6 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
        >
          Back to Home
        </button>
      </a>
    </div>
  );
};
export default SubscriptionPlans;
