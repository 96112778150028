import { useEffect, useState } from "react";
import AddAddressComponent from "./AddAddressComponent";
import { deleteAddress, getAddress } from "./AddressUtils";
import { ToastContainer, toast } from "react-toastify";
import Loading from "../Common/Loading";

const AddressSelection = ({
  book,
  handleAddressChange,
  onClose,
  setAddress,
  address,
}) => {
  const [showAddaddressModal, setShowAddaddressModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleDeleteAddress = (address) => {
    deleteAddress(address, toast);
    setTimeout(() => {
      getAddress({ setAddress, setIsLoading });
    }, 1000);
  };

  return (
    <>
      <ToastContainer />
      {showAddaddressModal ? (
        <AddAddressComponent
          onClose={() => {
            setTimeout(() => {
              getAddress({ setAddress, setIsLoading });
            }, 1000);

            setShowAddaddressModal(!showAddaddressModal);
          }}
          setAddress={setAddress}
          address={address}
          toast={toast}
          book={book}
        />
      ) : (
        <div className="flex flex-col w-full h-full">
          {isLoading ? (
            <>
              <Loading />
            </>
          ) : (
            // Address List
            <>
              <div>
                {/* Header */}
                <div className="flex flex-row w-full h-84 px-[33px] items-center py-[27px]  shadow-md">
                  <div className="text-2xl font-semibold text-customBlue line-clamp-1 w-11/12  flex justify-start ">
                    {book?.title}
                  </div>
                  <div className="w-1/12 flex justify-end"></div>
                  <div className="w-1/12 flex justify-end">
                    <button
                      className="text-xl sm:text-2xl font-bold text-gray-600 hover:text-gray-800"
                      onClick={onClose}
                    >
                      &times;
                    </button>
                  </div>
                </div>

                {/* Header 1 */}
                <div className="flex flex-row h-[56px] border-b  mx-[33px] mt-6  items-center ">
                  <div className="text-xl font-semibold  text-customBlue line-clamp-1 w-11/12  flex justify-start mb-2">
                    Select Address
                  </div>
                  <div className="w-3/12 flex justify-end mb-2">
                    <div className="flex items-end justify-end flex-grow h-11 my-[33px]  ">
                      <button
                        onClick={() => setShowAddaddressModal(true)}
                        className="  w-[120px] flex flex-row items-center   gap-2  bg-customSkyBlue justify-center text-customBlue h-11 rounded-lg px-2"
                      >
                        <i className="fa fa-plus border border-customBlue text-[12px] p-[1px] rounded w-4 h-4" />
                        <span className="text-xs">Add Address</span>
                      </button>
                    </div>
                  </div>
                </div>

                {/* Address List */}
                <div className="flex flex-col w-full h-full  overflow-y-auto mt-6 space-y-4">
                  {address?.map((addr, index) => (
                    <div
                      key={index}
                      className="flex flex-col  border  mx-[33px] px-3 py-[18px]  rounded-lg text-gray-600 hover:border-customYellow hover:text-customBlue "
                      onClick={() => handleAddressChange(addr)}
                    >
                      <div className="flex flex-row">
                        <div className="flex items-center justify-center">
                          {" "}
                          <i className=" fa-solid fa-location-dot text-2xl me-3" />
                        </div>
                        <div className="  flex-col   flex justify-start">
                          <div className="text-base  font-semibold   line-clamp-1">
                            {addr.name}
                          </div>
                          <div className="text-sm  line-clamp-1">
                            {addr.phoneNumber}
                          </div>
                        </div>
                      </div>

                      <div className="h-[1px] w-full bg-gray-300 my-[10px]"></div>
                      {/* Address Details */}
                      <div className="text-sm">
                        {addr.addressLine1} {addr.addressLine2} {addr.city}{" "}
                        {addr.state} {addr.pincode} {addr.country}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};
export default AddressSelection;
