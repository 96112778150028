
import { handleBookmarkClick, handleRemoveBookmark } from "./BooksUtil";
import { ReactComponent as OpenBook } from "../../Assets/icons/open_book.svg";

const MbBookDetailComponent = ({
  book,
  onClose,
  isBookmarked,
  setBookmarks,
  orderBook
}) => {
  if (!book) return null;
  let isBookmarkedVar = isBookmarked;


  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="fixed bottom-0  flex-col bg-white   z-50 w-full   h-10/12 min-h-[10/12] max-h-[11/12]   rounded-t-2xl ">
        {/* Header */}
        <div className="flex flex-row min-h-[56px]   shadow-sm  items-center ps-[33px] pe-[18px]">
          <div className="text-lg font-semibold text-customBlue line-clamp-1 w-11/12  flex justify-start ">
            {book.title}
          </div>
          <div className="w-1/12 flex justify-end">
            <button
              className="text-xl sm:text-2xl font-bold text-gray-600 hover:text-gray-800"
              onClick={onClose}
            >
              &times;
            </button>
          </div>
        </div>
        {/* Header 1 */}
        <div className="flex flex-row h-[56px] border-b  mx-[33px]  items-center ">
          <div className="text-sm  text-customBlue line-clamp-1 w-11/12  flex justify-start">
            By {book.author}
          </div>
          <div className="w-1/12 flex justify-end">
            {isBookmarkedVar ? (
              <i
                className="fa-regular fa-bookmark text-base sm:text-lg md:text-xl text-indigo-900 cursor-pointer"
                onClick={() => {
                  handleRemoveBookmark(book, setBookmarks);
                  isBookmarkedVar = false;
                }}
              />
            ) : (
              <i
                className="fa-solid fa-bookmark text-base sm:text-lg md:text-xl text-gray-400 cursor-pointer hover:text-indigo-600"
                onClick={() => {
                  handleBookmarkClick(book);
                  isBookmarkedVar = true;
                }}
              />
            )}
          </div>
        </div>
        {/* Image */}
        <div className="w-full flex items-center justify-center">
          <img
            src={book.image_url}
            alt={book.title}
            className="w-[90px] h-[137px] object-cover border my-6 "
          />
        </div>
        
        <div className="w-full px-[33px] mt-[16px] mb-[46px] h-[216px] overflow-y-scroll">
          {book.description || "No description available."}
        </div>
        {/* Divider */}
        <div className="w-full h-[1px] bg-gray-200 shadow"></div>
        <div className="w-full px-[16px] my-[16px]  ">
          <button
            onClick={() => orderBook({book, onClose})}
            className="  flex flex-row items-center w-full  px-[16px] bg-customBlue justify-center text-white h-11 rounded-lg"
          >
            <OpenBook width="19" height="19" fill="currentColor" />
            <span className="ml-2">Rent Now</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default MbBookDetailComponent;
