import { ReactComponent as OpenBook } from "../../Assets/icons/open_book.svg";

const BookListsComponent = ({ title, books, setOpenBook ,orderBook}) => {
  return (
    <>
      <>
        <div className="flex flex-col w-full mt-[30px] pt-[2px] ml-[35px] ">
          <div className=" text-2xl font-semibold flex w-full justify-start">
            {title}
          </div>
          <div className=" flex flex-row overflow-x-scroll overflow-y-auto  mt-[32px]  me-[32px]">
            {books.length > 0 &&
              books.map((book) => (
                <>
                  <div
                    className="w-[300px] h-[350px] flex flex-col   me-[22px] ms-[24px]  cursor-pointer  "
                    onClick={() => setOpenBook(book)}
                  >
                    {" "}
                    <div className="w-[150px] min-h-[240px]">
                      <img
                        src={book.image_url}
                        alt={book.title}
                        className="w-full h-full object-cover border"
                      />
                    </div>
                    <div className="flex flex-col w-full h-full mt-[11px]">
                      <div className="text-xl font-semibold h-[50px] line-clamp-2">
                        {book.title}
                      </div>
                      {/* <div className="text-md text-gray-500 mt-[19px]">
                        By {book.author}
                      </div> */}

                      <div className="h-[32px] w-[98px]  bg-customSkyBlue hover:bg-customBlue  hover:text-white  rounded-lg flex items-center justify-center  mt-[11px]  "onClick={(event) => {
                          event.stopPropagation();
                          orderBook({ book });
                        }}>
                        <div className="">
                          <OpenBook
                            width="14"
                            height="14"
                            fill="currentColor"
                          />
                        </div>
                        <div className="text-xs font-semibold ms-[8px] ">
                          {" "}
                          Rent Now
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
          </div>
        </div>
        <div className="w-full  flex justify-center mt-10">
          <div className="w-3/4 h-[1px] bg-gray-200"></div>
        </div>
      </>
    </>
  );
};

export default BookListsComponent;
