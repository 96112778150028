import MbTitleBar from "../Common/MbTitleBar";
import DidYouKnow from "../Extras/DidYouKnowComponent";
import { ReactComponent as OpenBook } from "../../Assets/icons/open_book.svg";

const MbBookmarkComponent = ({ bookmarks, orderBook, setOpenBook }) => {
  return (
    <>
      <MbTitleBar />
      <div className="h-full w-full overflow-y-scroll">
        <div className="w-full mt-6 px-3">
          <DidYouKnow />
        </div>
        <div className="w-full mt-6 grid grid-cols-2 gap-5 px-[22px]">
          {bookmarks.map((book) => (
            <>
              <div
                className="bg-[#F7F9FB] px-3 py-[16px] flex flex-col rounded-lg cursor-pointer"
                onClick={() => setOpenBook(book)}
              >
                <img
                  src={book.image_url}
                  alt={book.title}
                  className="w-[78px] h-[117px] object-cover border"
                />
                <div className=" flex flex-col h-[71px] mt-[16px]">
                  <div className="text-base font-semibold line-clamp-2 ">
                    {book.title}
                  </div>
                  <div className="text-xs text-gray-500  line-clamp-1">
                    By {book.author}
                  </div>
                </div>
                <div className=" w-full justify-start items-start">
                  <button
                    onClick={orderBook}
                    className="  flex flex-row items-center w-[113px]   bg-customBlue justify-center text-white h-11 rounded-lg"
                  >
                    <OpenBook width="19" height="19" fill="currentColor" />
                    <span className="ml-1 text-sm">Rent Now</span>
                  </button>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
    </>
  );
};

export default MbBookmarkComponent;
