import React, { useEffect, useState } from "react";
import { getBookmarks, handleRemoveBookmark } from "../Books/BooksUtil";
import BookDetailComponent from "../Books/BookDetailComponent";
import { useNavigate } from "react-router-dom";
import Loading from "../Common/Loading";
import MbBookmarkComponent from "./MbBookmarkComponent";
import MbBookDetailComponent from "../Books/mbBookDetailComponent";
import DkBookmarkComponent from "./DkBookmarkComponent";
import OrderComponent from "../Order/OrderComponent";

const BookmarkComponent = ({ setSelectedTab }) => {
  const [bookmarks, setBookmarks] = useState([]);
  const [openBook, setOpenBook] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isBookOrder, setIsBookOrder] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    setSelectedTab("bookmarks");
    getBookmarks(setBookmarks, setLoading);
  }, []);
  const orderBook = ({ book, onClose }) => {
    setIsBookOrder(book);
    if (onClose) {
      onClose();
    }
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="h-full w-full flex flex-col md:hidden">
            <MbBookmarkComponent
              bookmarks={bookmarks}
              orderBook={orderBook}
              setOpenBook={setOpenBook}
            />
          </div>
          <div className="hidden md:flex flex-col h-full w-full">
            <DkBookmarkComponent
              bookmarks={bookmarks}
              orderBook={orderBook}
              setOpenBook={setOpenBook}
            />
          </div>
        </>
      )}

      <>
        {openBook && (
          <>
            <div className="hidden md:flex">
              {" "}
              <BookDetailComponent
                book={openBook}
                onClose={() => setOpenBook(null)}
                orderBook={orderBook}
              />
            </div>
            <div className="md:hidden">
              <MbBookDetailComponent
                book={openBook}
                onClose={() => setOpenBook(null)}
                orderBook={orderBook}
              />
            </div>
          </>
        )}
      </>
      <>
        {isBookOrder && (
          <>
            <OrderComponent
              isBookOrder={isBookOrder}
              setIsBookOrder={setIsBookOrder}
            />
          </>
        )}
      </>
    </>
  );
};

export default BookmarkComponent;
