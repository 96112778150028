import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import BooksComponent from "../Books/BooksComponent";
import { handleSearch } from "./SearchUtils";
import Loading from "../Common/Loading";
import { truncateText } from "../Books/BooksUtil";
import MbTitleBar from "../Common/MbTitleBar";
import { ReactComponent as OpenBook } from "../../Assets/icons/open_book.svg";

const SearchComponent = ({
  searchTerm,
  setSearchTerm,
  handleKeyPress,
  handleSearchRedirect,
  setOpenBook,
  orderBook,
  setIsSearchTrue,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  const [booksDetails, setBooksDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleRequestBook = () => {
    navigate(`/request-book`);
  };

  useEffect(() => {
    handleSearch({ searchTerm, setBooksDetails, setIsLoading, setError });
  }, [searchTerm]);
  return (
    <>
      <div className="flex flex-col h-full ">
        <>
          <div className="flex flex-col  md:hidden">
            <MbTitleBar />
            <div className="w-full flex flex-row px-4 items-center">
              <>
                <div className="w-1/12">
                  <i
                    className="fa fa-arrow-left mr-4 fa-lg cursor-pointer"
                    onClick={() => {
                      setIsSearchTrue(false);
                      setSearchTerm("");
                    }}
                  />
                </div>
                <div className=" w-11/12 h-[48px] border rounded-3xl justify-start px-4 items-center flex my-6">
                  <input
                    className="text-lg flex-grow placeholder-gray-500 focus:outline-none"
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyPress={handleKeyPress}
                  />
                  <button
                    className="p-4 text-gray-500 hover:text-gray-700"
                    onClick={() => handleSearchRedirect(searchTerm)}
                    aria-label="Search books"
                  >
                    <i className="fa-solid fa-search" />
                  </button>
                </div>
              </>
            </div>
          </div>
          <>
            <div className="hidden  md:flex flex-row w-full h-[84px] px-[35px] pt-[22px] pb-[12px] border-b border-gray-200 ">
              <div className="w-1/12 items-center justify-center flex">
                <i
                  className="fa fa-arrow-left mr-4 fa-lg cursor-pointer"
                  onClick={() => {
                    setIsSearchTrue(false);
                    setSearchTerm("");
                  }}
                />
              </div>
              <div className="w-5/12 items-center  flex">
                <h1 className="text-2xl font-semibold ">Home</h1>
              </div>
              <div className="w-6/12 flex justify-end">
                <div className="w-[484px] h-[40] border rounded-3xl justify-start px-4 items-center flex">
                  <input
                    className=" text-lg flex-grow placeholder-gray-500 focus:outline-none"
                    type="text"
                    placeholder="Search books by name/author/genre"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyPress={handleKeyPress}
                  />
                  <button
                    className="p-4 text-gray-500 hover:text-gray-700"
                    onClick={() => handleSearchRedirect(searchTerm)}
                    aria-label="Search books"
                  >
                    <i className="fa-solid fa-search" />
                  </button>
                </div>
              </div>
            </div>
          </>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <div className="w-full overflow-y-auto grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-5 px-[22px] pt-6">
                {booksDetails.length > 0 &&
                  booksDetails.map((book, index) => (
                    <>
                      <div
                        className="bg-[#F7F9FB] px-3 py-[16px] flex flex-col rounded-lg cursor-pointer"
                        onClick={() => setOpenBook(book)}
                      >
                        <img
                          src={book.image_url}
                          alt={book.title}
                          className="w-[78px] h-[117px] object-cover border"
                        />
                        <div className=" flex flex-col h-[71px] mt-[16px]">
                          <div className="text-base font-semibold line-clamp-2 ">
                            {book.title}
                          </div>
                          <div className="text-xs text-gray-500  line-clamp-1">
                            By {book.author}
                          </div>
                        </div>
                        <div className=" w-full justify-start items-start">
                          <button
                            onClick={orderBook}
                            className="  flex flex-row items-center w-[113px]   bg-customBlue justify-center text-white h-11 rounded-lg"
                          >
                            <OpenBook
                              width="19"
                              height="19"
                              fill="currentColor"
                            />
                            <span className="ml-1 text-sm">Rent Now</span>
                          </button>
                        </div>
                      </div>
                    </>
                  ))}
              </div>
            </>
          )}
        </>
      </div>
    </>
  );
};

export default SearchComponent;
