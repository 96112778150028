

import BookListComponent2 from "../Books/BookListsComponent2";
import BookListsComponent from "../Books/BookListsComponent";
import DidYouKnow from "../Extras/DidYouKnowComponent";


const HomeDk = ({
  trendingBooks,
  genre1Books,
  genre2Books,
  scienceBooks,
  romanceBooks,
  selfHelpBooks,
  setOpenBook,
  handleSearchRedirect,
  handleKeyPress,
  searchTerm,
  setSearchTerm,
  orderBook,
  onClose
}) => {
  return (
    <>
      <>
        <div className=" flex flex-row w-full h-[84px] px-[35px] pt-[22px] pb-[12px] border-b border-gray-200 ">
          <div className="w-1/2 ">
            <h1 className="text-2xl font-semibold ">Home</h1>
          </div>
          <div className="w-1/2 flex justify-end">
            <div className="w-[484px] h-[40] border rounded-3xl justify-start px-4 items-center flex">
              <input
                className=" text-lg flex-grow placeholder-gray-500 focus:outline-none"
                type="text"
                placeholder="Search books"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyPress={handleKeyPress}
              />
              <button
                className="p-4 text-gray-500 hover:text-gray-700"
                onClick={() => handleSearchRedirect(searchTerm)}
                aria-label="Search books"
              >
                <i className="fa-solid fa-search" />
              </button>
            </div>
          </div>
        </div>
        <div className="flex flex-col overflow-x-hidden overflow-y-auto">
        <>
          <BookListComponent2
            title="New Additions"
            books={trendingBooks}
            setOpenBook={setOpenBook}
            orderBook={orderBook}
            onClose={onClose}

          />
        </>
        <>
          <div className=" w-full mt-[30px] ps-[35px] pe-[34px] p-5 rounded-xl  ">
            <DidYouKnow />
          </div>
        </>

        <>
          <BookListsComponent
            title="Trending"
            books={trendingBooks}
            setOpenBook={setOpenBook}
            orderBook={orderBook}
            onClose={onClose}
          />
        </>
        <>
          <BookListComponent2
            title="Fiction"
            books={genre1Books}
            setOpenBook={setOpenBook}
            orderBook={orderBook}
            onClose={onClose}
          />
        </>
        <>
          <BookListsComponent
            title="Fantasy"
            books={genre2Books}
            setOpenBook={setOpenBook}
            orderBook={orderBook}
            onClose={onClose}
          />
        </>
        <>
          <BookListComponent2
            title="Science"
            books={scienceBooks}
            setOpenBook={setOpenBook}
            orderBook={orderBook}
            onClose={onClose}
          />
        </>
        <>
          <BookListsComponent
            title="Romance"
            books={romanceBooks}
            setOpenBook={setOpenBook}
            orderBook={orderBook}
            onClose={onClose}
          />
        </>
        <>
          <BookListComponent2
            title="Self-Help"
            books={selfHelpBooks}
            setOpenBook={setOpenBook}
            orderBook={orderBook}
            onClose={onClose}
          />
        </>
        </div>
        
      </>
      
    </>
  );
};

export default HomeDk;
